.layout-activities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    margin: 0 ;
}
.activity-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    margin: 0 ;
}
..activity {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;

}